const languages = [
  {
    name: 'English',
    code: 'en_US',
  },
  {
    name: 'toki pona',
    code: 'toki',
  },
]

export default languages